import firebase from "gatsby-plugin-firebase"
import "firebase/analytics"

const analytics = firebase.analytics()

export const onRouteUpdate = ({ location, prevLocation }) => {
  try {
    analytics.logEvent("page_view", { fromURL: prevLocation ? prevLocation.pathname : null, toURL: location.pathname })
  } catch (error) {
    console.error(error)
  }
}