module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Share Your Story - Recording your family history","short_name":"YourStory","start_url":"/","background_color":"#f5f0ee","theme_color":"#887973","display":"standalone","icon":"images/ys-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"449cf217f4923d7bc24c672fb97ac636"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://db2a4f3896e34cf7beeea9e315364473@o490669.ingest.sentry.io/5554969","autoSessionTracking":true,"sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Muli:300,400,500,600,700,800","Cormorant+Infant:300,500","Montserrat:200","Sacramento"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
